import { useContext } from 'react'

import dynamic from 'next/dynamic'
import { PrpProvider } from '/contexts/prpContext'

import GetStaticPaths from '/lib/shop/getStaticPaths'
import GetStaticProps from '/lib/shop/getStaticProps'
import useProductResultPage from '../../../../../../../../../hooks/useProductResultPage';

const ProductResultPage = dynamic(() => import('/layout/productResultPage'))

export async function getStaticPaths() {
  return GetStaticPaths()
}

export async function getStaticProps({ params }) {
  return await GetStaticProps(params)
}

const Shop = ({ params, response, encodedEdgeFlags }) => {

  return (
    <>
      <PrpProvider initialState={response} initialParams={params}>
        <LayoutComponent />
      </PrpProvider>
    </>
  )
}

const LayoutComponent = () => {
  const isFacetResultPage = useProductResultPage(x => x.isFacetResultPage)

  return <ProductResultPage isFrp={isFacetResultPage} /> 
}

export default Shop
